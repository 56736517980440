import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroMini from "../components/heroSmall"

const ServicesPage = () => (
  <Layout>
    <Seo title="Training" />
    <HeroMini title="Training"/>
    <main className="section has-text-black">
  <div className="container">
  <div className="columns">
  <div className="column">
    <p className="mb-6">Depend on our full-spectrum of consultation services to provide the ingenuity you need to solve your challenges on time and within budget.</p>
  </div>
  </div>

  {/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">HTML</p>
  </div>
  </div> */}

  <div className="columns">
  <div className="column content has-text-centered">
  
    <p className="is-size-4 fusionfont">Teams</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/color/48/000000/microsoft-teams.png"/>
    <p></p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Sharepoint</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/nolan/64/ms-share-point.png"/>
    <p></p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Planner/Tasks</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/dusk/64/000000/microsoft-planner.png"/>
    <p></p>
  </div>

  

</div>

<div className="columns">

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Lists</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/color/48/000000/separated-lists.png"/>
    <p></p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Power Automate</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/fluent/48/000000/microsoft-power-automate-2020.png"/>
    <p></p>
  </div>

  <div className="column content has-text-centered">
  
  <p className="is-size-4 fusionfont">Power Apps</p>
  <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/fluent/48/000000/microsoft-power-apps-2020.png"/>
  <p></p>
</div>

</div>

</div>
</main>
  </Layout>
)

export default ServicesPage
